import externalRoutes from './externalRoutes';

/**
 * The root domain.
 */
const rootDomain = (): string => {
  // Find the current hostname
  const { hostname } = window.location;

  // Remove "dashboard." from the hostname
  return hostname.replace('dashboard.', '');
};

/**
 * The base API URL.
 */
export const apiUrl = (): string => {
  // Construct the full URL
  const apiUrl: string = `${window.location.protocol}//api.${rootDomain()}`;

  // If running locally
  if (useRuntimeConfig().public.environment === 'local') {
    // If not running Cypress tests
    if (window.Cypress === undefined) {
      // Append port 8000
      return `${apiUrl}:8000`;
    }
  }

  // Return as is
  return apiUrl;
};

/**
 * The base admin dashboard URL.
 */
export const adminDashboardUrl = (): string => {
  // Construct the full URL
  const adminUrl: string = `${window.location.protocol}//admin.${rootDomain()}`;

  // If running locally
  if (useRuntimeConfig().public.environment === 'local') {
    // If not running Cypress tests
    if (window.Cypress === undefined) {
      // Append port 8000
      return `${adminUrl}:8000`;
    }
  }

  // Return as is
  return adminUrl;
};

/**
 * Ensure path starts with a "/".
 *
 * @param path
 */
export const normalisePath = (path: string): string => {
  if (path.charAt(0) !== '/') {
    return `/${path}`;
  }

  return path;
};

/**
 * The application's base URL, including protocol.
 */
export const baseUrl = (): string =>
  `${window.location.protocol}//${window.location.host}`;

/**
 * Is the current route an external route.
 */
export const isExternalRoute = (): boolean => {
  // If the current route matches a defined route
  if (useRoute().matched[0]) {
    // Determine if the current route is included in the defined external routes
    return externalRoutes.includes(useRoute().matched[0].path);
  }

  // The route is not external
  return false;
};

/**
 * Construct a help site link using the help base URL from the NUXT_PUBLIC_HELP_BASE environment
 * variable.
 *
 * @param path
 */
export const helpUrl = (path: string): string =>
  useCommonStore().partner.help_centre_url;
// path = normalisePath(path);
//
// return useCommonStore().partner.help_centre_url + normalisePath(path);

/**
 * The path to use as the homepage when redirecting from login, create/edit forms, and "Back to
 * dashboard" links.
 */
export const homePath = (): string => {
  // If the user has the sell and redeem role
  if (useAuthStore().role === 'sell_and_redeem') {
    // Redirect to the terminal
    return '/terminal';
  }

  // If the user has the redeem role
  if (useAuthStore().role === 'redeem') {
    // Redirect to the redeem terminal
    return '/terminal/redeem';
  }

  // Redirect to the homepage
  return '/products';
};
