import { normalisePath } from '~/utils/routes';

/**
 * The number of pixels that should be left between the scrolled to element and the top of the
 * screen.
 */
const scrollTopMargin: number = 80;

/**
 * Scroll to the highest element with the 'has-error' class.
 */
export const scrollToError = async (): Promise<void> => {
  // Wait for the DOM to update so has-error classes are set
  await nextTick();

  // Find all has-error classes on the page
  const errors: HTMLCollectionOf<Element> =
    document.getElementsByClassName('has-error');

  // Find the offset top for each error element
  const offsetTops: number[] = [...errors].map(
    (element: Element): number => (element as HTMLElement).offsetTop,
  );

  // Find the highest error element on the page
  const highest: number = Math.min(...offsetTops);

  // Scroll to the element
  document
    .getElementById('container')
    ?.scrollTo({ top: highest - scrollTopMargin });
};

/**
 * Generate a store URL.
 *
 * @param subdomain
 * @param path
 */
export const storeRoute = (subdomain?: string, path?: string): string => {
  // Retrieve the partner's store root domain
  let url: string = useCommonStore().partner.store_root_domain;

  // If a subdomain is to be applied
  if (subdomain) {
    // Set the subdomain
    url = `${subdomain}.${url}`;
  }

  // If a path is to be applied
  if (path) {
    // Normalise the path
    path = normalisePath(path);

    // Set the path
    url = `${url}${path}`;
  }

  return url;
};

/**
 * Return the root domain of the current app.
 */
export const rootDomain = (): string => {
  // Split the hostname by "."
  const hostnameSegments: string[] = window.location.hostname.split('.');

  // Remove the first segment
  hostnameSegments.shift();

  // Join the remaining segments
  return hostnameSegments.join('.');
};

/**
 * Debounce a function.
 *
 * Note, we have no idea how this works, and trust its magic.
 *
 * Stolen from https://blog.logrocket.com/debounce-throttle-vue/.
 */
export function debounce(fn: Function, wait: number) {
  let timer: NodeJS.Timeout;

  return function (...args: any[]): void {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }

    // @ts-ignore
    const context = this; // get the current context

    timer = setTimeout((): void => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
}

/**
 * Copy text to the clipboard.
 */
export const copy = (text: string): void => {
  navigator.clipboard.writeText(text);
};

/**
 * Trigger a download of provided CSV data.
 */
export const downloadCsvFile = (data: string, filename: string): void => {
  // Construct a blob from the returned CSV data
  const blob: Blob = new Blob([data], { type: 'text/csv' });

  // Generate an object URL for the blob
  const url: string = URL.createObjectURL(blob);

  // Create an anchor link in the document
  const a: HTMLAnchorElement = document.createElement('a');

  // Use the blob's object URL as the anchor's destination
  a.setAttribute('href', url);

  // Set the anchor tag's download attribute with the desired filename
  a.setAttribute('download', filename);

  // Simulate clicking the anchor to download the file
  a.click();

  // Remove the anchor element
  a.remove();

  // Revoke the blob's object URL
  URL.revokeObjectURL(url);
};

/**
 * Trigger a download of provided PDF data.
 */
export const downloadPdfFile = (data: string, filename: string): void => {
  // Construct a blob from the returned PDF data
  const blob: Blob = new Blob([data], { type: 'application/pdf' });

  // Generate an object URL for the blob
  const url: string = URL.createObjectURL(blob);

  // Create an anchor link in the document
  const a: HTMLAnchorElement = document.createElement('a');

  // Use the blob's object URL as the anchor's destination
  a.setAttribute('href', url);

  // Set the anchor tag's download attribute with the desired filename
  a.setAttribute('download', filename);

  // Simulate clicking the anchor to download the file
  a.click();

  // Remove the anchor element
  a.remove();

  // Revoke the blob's object URL
  URL.revokeObjectURL(url);
};

/**
 * Validate validity.
 */
export const validateValidity = (validity: Validity): ValidityErrors => {
  const validityErrors: ValidityErrors = {};

  if (validity.type === 'period_from_purchase') {
    const periodFromPurchase: PeriodFromPurchase =
      validity as PeriodFromPurchase;

    let months: string | null = null;
    let days: string | null = null;

    if (periodFromPurchase.values.months === '') {
      months = 'Enter months';
    }

    if (periodFromPurchase.values.days === '') {
      days = 'Enter days';
    }

    if (
      Number(periodFromPurchase.values.months) === 0 &&
      Number(periodFromPurchase.values.days) === 0
    ) {
      months = 'Enter months';
      days = 'Enter days';
    }

    if (months || days) {
      validityErrors.values = {
        months: months as string,
        days: days as string,
      };
    }
  }

  if (validity.type === 'absolute_dates') {
    const absoluteDates: AbsoluteDates = validity as AbsoluteDates;

    let startDateError: string | null = null;
    let endDateError: string | null = null;

    if (absoluteDates.values.start_date === '') {
      startDateError = 'Enter a from date';
    }

    if (absoluteDates.values.end_date === '') {
      endDateError = 'Enter a to date';
    }

    const endDate: Date = new Date(absoluteDates.values.end_date);

    if (absoluteDates.values.end_date && endDate < new Date()) {
      endDateError = 'The to date must be in the future';
    }

    if (
      !(startDateError && endDateError) &&
      endDate < new Date(absoluteDates.values.start_date)
    ) {
      endDateError = 'The to date must be after the from date';
    }

    if (startDateError || endDateError) {
      validityErrors.values = {
        start_date: startDateError as string,
        end_date: endDateError as string,
      };
    }
  }

  if (validity.days) {
    if (validity.days?.length === 0) {
      validityErrors.days = 'At least one day is required';
    }
  }

  return validityErrors;
};

/**
 * Validate schedules.
 */
export const validateSchedules = (schedule: {
  activationDate?: string | null;
  deactivationDate?: string | null;
}): ScheduleErrors => {
  const scheduleErrors: ScheduleErrors = {};

  if (schedule.activationDate !== null) {
    let error: string | null = null;

    // If an activation date has been entered
    if (schedule.activationDate) {
      // If the activation date is not a valid date
      if (!isValidDate(new Date(schedule.activationDate))) {
        error = 'Enter a valid date';
      } else if (Date.parse(schedule.activationDate) < Date.now()) {
        // The activation date is before the current date
        error = 'Date cannot be in the past';
      }
    } else {
      error = 'Enter an activation date';
    }

    // If an error was found, set the activation error attribute
    if (error) {
      scheduleErrors.activationDate = error;
    }
  }

  if (schedule.deactivationDate !== null) {
    let error: string | null = null;

    // If a deactivation date has been entered
    if (schedule.deactivationDate) {
      // If the deactivation date is invalid
      if (!isValidDate(new Date(schedule.deactivationDate))) {
        error = 'Enter a valid date';
      } else if (
        schedule.activationDate &&
        !scheduleErrors.activationDate &&
        Date.parse(schedule.deactivationDate) <=
          Date.parse(schedule.activationDate)
      ) {
        // There is an activation date and the deactivation date is before activation
        error = 'Cannot be before activation';
      } else if (Date.parse(schedule.deactivationDate) < Date.now()) {
        // The deactivation date is before the current date
        error = 'Date cannot be in the past';
      }
    } else {
      error = 'Enter a deactivation date';
    }

    // If an error was found, set the deactivation error attribute
    if (error) {
      scheduleErrors.deactivationDate = error;
    }
  }

  return scheduleErrors;
};

/**
 * Validate tags have keys and values set.
 */
export const validateTags = (tags: KeyValuePairs): string => {
  // Check if hasDuplicateTags is set on the Pinia store set on the common Pinia store
  const duplicateTags: boolean =
    (useCommonStore().piniaStore as PiniaStoreWithDuplicateTags)
      .hasDuplicateTags ?? false;

  // If so, return error
  if (duplicateTags) {
    return 'Tag names must be unique';
  }

  // Convert the tags into a Javascript friendly format array of objects
  const tagsArray: ObjectFormattedTag[] = Object.entries(tags).map(
    ([key, value]): ObjectFormattedTag => ({ key, value }),
  );

  let error: string = '';

  // Check that each tag has a key and value set
  tagsArray.forEach((tag: ObjectFormattedTag): void => {
    if (tag.value === '') {
      error = 'Enter a tag value';
    }

    if (tag.key === '') {
      error = 'Enter a tag key';
    }
  });

  return error;
};

/**
 * Validate a date is a valid date.
 *
 * Checks if the given date is a Date object, and that it is not an "Invalid Date".
 */
export const isValidDate = (date: any): boolean =>
  // @ts-ignore since we are explicitly checking the date format against number
  date instanceof Date && !isNaN(date);

/**
 * Validate a model's scheduled activation and deactivation dates.
 *
 * If the model has a non-null activation date and/or deactivation date, ensure the field is not
 * empty and is a valid date.
 */
export const hasValidScheduledActivation = (model: ScheduledModel): boolean => {
  // If the model's activation date is not null
  if (model.activationDate !== null) {
    // Check that the model's activation date is not an empty string
    if (model.activationDate === '') {
      return false;
    }

    // Check that the model's activation date is a valid date
    if (!isValidDate(new Date(model.activationDate as string))) {
      return false;
    }
  }

  // If the model's deactivation date is not null
  if (model.deactivationDate !== null) {
    // Check that the model's deactivation date is not an empty string
    if (model.deactivationDate === '') {
      return false;
    }

    // Check that the model's deactivation date is a valid date
    if (!isValidDate(new Date(model.deactivationDate as string))) {
      return false;
    }
  }

  return true;
};

/**
 * Extract activationDate and deactivationDate from an array of schedules.
 *
 * Finds the first activation and deactivation schedules in the array and returns the scheduled_for
 * timestamp of each.
 */
export const extractScheduleTimestamps = (
  schedules: Schedule[],
): {
  activationDate: string | null;
  deactivationDate: string | null;
} => {
  let activationDate: string | null = null;
  let deactivationDate: string | null = null;

  // If the schedules array is not empty
  if (schedules.length) {
    // Find the first activation schedule
    const activationSchedule: Schedule | undefined = schedules.find(
      (schedule: Schedule) =>
        schedule.is_activation_change && schedule.attributes.is_active === true,
    );

    // Extract the schedule's scheduled_for timestamp
    activationDate = activationSchedule?.scheduled_for ?? null;

    // Find the first deactivation schedule
    const deactivationSchedule: Schedule | undefined = schedules.find(
      (schedule: Schedule) =>
        schedule.is_activation_change &&
        schedule.attributes.is_active === false,
    );

    // Extract the schedule's scheduled_for timestamp
    deactivationDate = deactivationSchedule?.scheduled_for ?? null;
  }

  return {
    activationDate,
    deactivationDate,
  };
};

/**
 * Capitalise the first letter of the given string.
 */
export const ucFirst = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);
