import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

/**
 * The terminal Pinia store.
 */
export const useTerminalStore = defineStore({
  id: 'terminal',

  state: () => ({
    /**
     * The generated order.
     */
    order: null as Order | null,

    /**
     * The payment reference to apply to the order.
     */
    paymentReference: '' as string,

    /**
     * The current redeem location.
     */
    redeemLocation: null as RedeemLocation | null,

    /**
     * The current sales channel.
     */
    salesChannel: null as SalesChannel | null,

    /**
     * The basket.
     */
    basket: null as Basket | null,

    /**
     * The buyer.
     */
    buyerEmail: '' as string,

    /**
     * The product.
     */
    product: null as BasketProduct | null,

    /**
     * The redeem locations.
     */
    redeemLocations: [] as RedeemLocation[],

    /**
     * The products.
     */
    products: [] as Product[],

    /**
     * The discounts.
     */
    discounts: [] as BasketDiscount[],

    /**
     * The discount code has an error.
     */
    hasCodeError: false as boolean,

    /**
     * The staff discount amount.
     */
    staffDiscountAmount: null as number | null,

    /**
     * The redeemables to be redeemed.
     */
    redeemables: [] as Redeemable[],

    /**
     * The number of redeemables that have been redeemed.
     */
    redemptionCount: null as number | null,

    /**
     * The reference of the voucher whose redeemables have been redeemed.
     */
    voucherReference: null as string | null,

    /**
     * The "back to" button option.
     */
    backToButton: null as { destination: string; text: string } | null,

    summaryPageProcessingDiscount: false as boolean,
  }),

  getters: {
    /**
     * The total number of redeemables.
     */
    redeemablesCount: (state): number => state.redeemables.length,

    /**
     * The valid redeemables.
     */
    validRedeemables: (state): Redeemable[] =>
      state.redeemables.filter(
        (redeemable: Redeemable): boolean => redeemable.invalid_reason === null,
      ),
  },

  actions: {
    initialiseBasket(): void {
      this.basket = {
        store_eni: (this.salesChannel as SalesChannel).eni,
        customer_id: uuidv4(),
        data: {
          products: [],
          shipping: {
            emails: [
              {
                email: '',
                recipient_name: '',
                from_name: '',
              },
            ],
          },
        },
      };
    },

    initialiseBasketProduct(product: Product): void {
      this.product = {
        ...product,
        basketData: {
          id: product.id,
          eni: product.eni,
          image_url: product.custom_fields.image_urls[0],
          from_name: '',
          recipient_name: '',
          message: '',
          addons: [],
          email: 0,
        },
      };
    },

    async addBasketProduct(product: BasketProduct): Promise<void> {
      // If the basket is not set
      if (this.basket === null) {
        // Initialise the basket
        this.initialiseBasket();
      }

      // Copy the basket
      const basket: Basket = JSON.parse(JSON.stringify(this.basket as Basket));

      // And push the product onto the basket copy
      basket.data.products.push(product.basketData as BasketItem);

      // Update the basket
      this.previewBasket(basket);
    },

    async updateBasketProduct(product: BasketProduct): Promise<void> {
      // Copy the basket
      const basket: Basket = JSON.parse(JSON.stringify(this.basket as Basket));

      // And update the copied basket's products
      basket.data.products[product.index as number] = product.basketData;

      // Update the basket
      this.previewBasket(basket);
    },

    async removeBasketProduct(product: BasketProduct): Promise<void> {
      // Copy the basket
      const basket: Basket = JSON.parse(JSON.stringify(this.basket as Basket));

      // And remove the product from the copied basket's products
      basket.data.products = basket.data.products
        .slice(0, product.index as number)
        .concat(basket.data.products.slice((product.index as number) + 1));

      // If no products are left in the basket
      if (basket.data.products.length === 0) {
        // Hard reset
        window.location.href = `/terminal/sell/${(this.salesChannel as SalesChannel).id}`;
      }

      // Update the basket
      this.previewBasket(basket);
    },

    async setBasketDiscount(discount: {
      id: string;
      eni: string;
      code?: string;
    }): Promise<void> {
      this.summaryPageProcessingDiscount = true;

      this.hasCodeError = false;

      // Copy the basket
      const basket: Basket = JSON.parse(JSON.stringify(this.basket as Basket));

      // And update the copied basket's discount
      basket.data.promotion = {
        id: discount.id,
        eni: discount.eni,
      } as BasketDiscount;

      // If the discount has a code set
      if (discount.code) {
        basket.data.promotion.code = discount.code;
      }

      // Update the basket
      await this.previewBasket(basket);

      this.summaryPageProcessingDiscount = false;
    },

    async removeBasketDiscount(): Promise<void> {
      this.summaryPageProcessingDiscount = true;

      // Copy the basket
      const basket: Basket = JSON.parse(JSON.stringify(this.basket as Basket));

      // And remove the promotion from the copied basket
      delete basket.data.promotion;

      // Update the basket
      await this.previewBasket(basket);

      this.summaryPageProcessingDiscount = false;
    },

    async previewBasket(basket: Basket | undefined = undefined): Promise<void> {
      // If no basket was passed in
      if (basket === undefined) {
        // Use a copy of the terminal's basket
        basket = JSON.parse(JSON.stringify(this.basket as Basket));
      }

      // Update the basket
      const response = await apiPost(
        '/terminal/basket/preview',
        this.sanitizeBasket(basket as Basket),
      );

      // Apply staff discount
      basket = await this.applyStaffDiscountToBasket({
        ...basket,
        ...response.basket,
      });

      // Update the local basket with the provided basket
      this.basket = {
        ...this.basket,
        ...basket,
      };
    },

    async validateBasketDiscountCode(code: string): Promise<void> {
      this.summaryPageProcessingDiscount = true;

      this.hasCodeError = false;

      try {
        // Validate discount code
        const response = await apiPost('/terminal/basket/promotion/code', {
          code,
        });

        // Set basket discount
        await this.setBasketDiscount({
          id: response.data.id,
          eni: response.data.eni,
          code,
        });
      } catch (error: any) {
        // If the request returns invalid or not found
        if (error.statusCode === 404 || error.statusCode === 422) {
          this.hasCodeError = true;

          this.summaryPageProcessingDiscount = false;

          return;
        }

        this.summaryPageProcessingDiscount = false;

        throw error;
      }
    },

    async fetchBasketDiscounts(): Promise<void> {
      // Fetch basket available discounts
      const response = await apiPost('/terminal/basket/check-promotions', {
        basket_id: (this.basket as Basket).id,
      });

      // Set the fetched discounts
      this.discounts = response.data;
    },

    sanitizeBasket(
      basket: Basket,
      includingStaffDiscount: boolean = true,
    ): Basket {
      // Remove basket generated data
      delete basket.data.list_price;
      delete basket.data.discounts;
      delete basket.data.tax;
      delete basket.data.cost;

      // For each product
      for (let i: number = 0; i < basket.data.products.length; i++) {
        const product: BasketItem = basket.data.products[i] as BasketItem;

        // Remove basket generated data
        delete product.list_price;
        delete product.discounts;
        delete product.cost;
        delete product.variant;

        if (includingStaffDiscount) {
          delete product.staff_discount;
        }

        // For each addon
        for (let ii: number = 0; ii < product.addons.length; ii++) {
          const addon: BasketItemAddon = product.addons[ii] as BasketItemAddon;

          // Remove basket generated data
          delete addon.list_price;
          delete addon.discounts;
          delete addon.cost;

          if (includingStaffDiscount) {
            delete addon.staff_discount;
          }
        }
      }

      return basket;
    },

    async applyStaffDiscountToBasket(basket: Basket): Promise<Basket> {
      const basketData = basket.data;
      let discount: number = this.staffDiscountAmount as number;

      // If discount is not set or is 0
      if (this.staffDiscountAmount === null || this.staffDiscountAmount === 0) {
        // Do nothing
        return basket;
      }

      // Get the list price of the basket
      let runningTotal: number = basketData.list_price as number;

      // If the basket has a promotion discount
      if (basketData.discounts && basketData.discounts.promotion_discount) {
        // Reduce the basket list price by the discount
        runningTotal -= basketData.discounts.promotion_discount;
      }

      // If the discount amount is more than the basket
      discount = Math.min(discount, runningTotal);

      this.staffDiscountAmount = discount;

      // Amount of staff discount applied
      let appliedDiscount: number = 0;

      // For each product
      for (let i: number = 0; i < basketData.products.length; i++) {
        const product: BasketItem = basketData.products[i];

        // Get the product's list price
        let productRunningTotal: number = product.list_price as number;

        // If the product has a promotion discount
        if (product.discounts && product.discounts.promotion_discount) {
          // Reduce the product's list price by the discount
          productRunningTotal -= product.discounts.promotion_discount;
        }

        // Calculate the proportion of the baskets running total that this product makes
        // up
        const productProportion = productRunningTotal / runningTotal;

        // Assign that proportion of the staff discount to the product
        let productDiscountAmount = Math.floor(productProportion * discount);

        // Ensure the discount amount does not exceed the product's total
        // value
        productDiscountAmount = Math.min(
          productDiscountAmount,
          productRunningTotal,
        );

        // Set the product's staff discount amount
        product.staff_discount = { discount_value: productDiscountAmount };

        // Update applied discount amount
        appliedDiscount += productDiscountAmount;

        // For each addon
        for (let i: number = 0; i < product.addons.length; i++) {
          const addon: BasketItemAddon = product.addons[i];

          // Get the addon's list price
          let addonRunningTotal: number = addon.list_price as number;

          // If the addon has a promotion discount
          if (addon.discounts && addon.discounts.promotion_discount) {
            // Reduce the addon's list price by the discount
            addonRunningTotal -= addon.discounts.promotion_discount;
          }

          // Calculate the proportion of the baskets running total that this addon makes
          // up
          const addonProportion: number = addonRunningTotal / runningTotal;

          // Assign that proportion of the staff discount to the addon
          let addonDiscountAmount: number = Math.floor(
            addonProportion * discount,
          );

          // Ensure the discount amount does not exceed the addon's total
          // value
          addonDiscountAmount = Math.min(
            addonDiscountAmount,
            addonRunningTotal,
          );

          // Set the addon's staff discount amount
          addon.staff_discount = { discount_value: addonDiscountAmount };

          // Update applied discount amount
          appliedDiscount += addonDiscountAmount;
        }
      }

      if (appliedDiscount !== discount) {
        let missing = discount - appliedDiscount;

        while (true) {
          // For each product
          for (let i: number = 0; i < basketData.products.length; i++) {
            const product: BasketItem = basketData.products[i];

            // Get the product's list price
            let productRunningTotal: number = product.list_price as number;

            // If the product has a promotion discount
            if (product.discounts && product.discounts.promotion_discount) {
              // Reduce the product's list price by the discount
              productRunningTotal -= product.discounts.promotion_discount;
            }

            // If the product has a staff discount
            if (product.staff_discount?.discount_value) {
              // Reduce the product's list price by the discount
              productRunningTotal -= product.staff_discount.discount_value;
            }

            // If the product total is not 0
            if (productRunningTotal > 0) {
              // @ts-ignore Increase the product staff discount by one unit
              product.staff_discount.discount_value++;

              // Reduce the missing amount by one unit
              missing--;
            }

            if (missing === 0) {
              // Stop here
              break;
            }

            // For each addon
            for (let i: number = 0; i < product.addons.length; i++) {
              const addon: BasketItemAddon = product.addons[i];

              // Get the addon's list price
              let addonRunningTotal: number = addon.list_price as number;

              // If the addon has a promotion discount
              if (addon.discounts && addon.discounts.promotion_discount) {
                // Reduce the addon's list price by the discount
                addonRunningTotal -= addon.discounts.promotion_discount;
              }

              // @ts-ignore If the addon has a staff discount
              if (addon.staff_discount.discount_value) {
                // @ts-ignore Reduce the addon's list price by the discount
                addonRunningTotal -= addon.staff_discount.discount_value;
              }

              // @ts-ignore If the addon total is not 0
              if (addonRunningTotal > 0) {
                // @ts-ignore Increase the addon staff discount by one unit
                addon.staff_discount.discount_value++;

                // Reduce the missing amount by one unit
                missing--;
              }

              if (missing === 0) {
                // Stop here
                break;
              }
            }

            if (missing === 0) {
              // Stop here
              break;
            }
          }

          if (missing === 0) {
            // Stop here
            break;
          }
        }
      }

      // Update the basket
      const response = await apiPost(
        '/terminal/basket/preview',
        this.sanitizeBasket(basket, false),
      );

      return response.basket as Basket;
    },

    async completeBasket(type: 'test' | 'live'): Promise<void> {
      // Use a copy of the terminal's basket
      const basket = JSON.parse(JSON.stringify(this.basket as Basket));

      // Validate the basket
      let response = await apiPost(
        '/terminal/basket/validate',
        this.sanitizeBasket(basket, false),
      );

      const data: {
        basket_id: string;
        payment_reference?: string;
        is_test?: boolean;
        buyer_email?: string;
      } = {
        basket_id: basket.id,
      };

      // If a payment reference is set
      if (this.paymentReference !== '') {
        // Include the payment reference when creating the order
        data.payment_reference = this.paymentReference;
      }

      // If the basket type is test
      if (type === 'test') {
        // Send a test flag in the request
        data.is_test = true;
      }

      // If a buyer email is set
      if (this.buyerEmail !== '') {
        // Include the buyer's email when creating the order
        data.buyer_email = this.buyerEmail;
      }

      // Validate the basket
      response = await apiPost('/terminal/basket/order', data);

      // Set the terminal's order
      this.order = response.data as Order;
    },

    /**
     * Redeem redeemables.
     */
    async redeem(): Promise<boolean> {
      try {
        await apiPost('/terminal/redeemables/redeem', {
          redeemables: (this.validRedeemables as Redeemable[]).map(
            (
              redeemable: Redeemable,
            ): {
              id: string;
              value?: number;
            } => {
              const redeemableData: {
                id: string;
                value?: number;
              } = { id: redeemable.id };

              if (redeemable.type === 'monetary') {
                redeemableData.value = redeemable.redemption_monetary_value;
              }

              return redeemableData;
            },
          ),
        });

        return true;
      } catch (error: any) {
        // If the request returns 403
        if (error.statusCode === 403) {
          // Return false to indicate the redemption failed
          return false;
        }

        throw error;
      }
    },

    /**
     * Retrieve a redeemable.
     */
    async retrieveRedeemable(redeemableId: string): Promise<void> {
      // Retrieve the redeemable
      const { data } = await apiGet(`/terminal/redeemables/${redeemableId}`);

      if ((data.type as string) === 'addon') {
        data.type = 'enhancement';
      }

      // Set the redeemable in the Pinia store
      this.redeemables = [data];
    },

    /**
     * Retrieve a sales channel.
     */
    async retrieveSalesChannel(salesChannelId: string): Promise<void> {
      // Retrieve the sales channel
      const { data } = await apiGet(`terminal/stores/${salesChannelId}`);

      // Convert the sales channel's banner to an object if it is an empty array
      if (Array.isArray(data.banner) && data.banner.length === 0) {
        data.banner = {};
      }

      data.categories = data.categories.map((category: Category): Category => {
        const categoryData: Category = category;

        // Convert the category's banner to an object if it is an empty array
        if (
          Array.isArray(categoryData.banner) &&
          categoryData.banner.length === 0
        ) {
          categoryData.banner = {};
        }

        return category;
      });

      // Set the sales channel in the Pinia store
      this.salesChannel = data;
    },

    /**
     * Retrieve a product.
     */
    async retrieveProduct(productId: string): Promise<void> {
      // Retrieve the product
      const { data } = await apiGet(`terminal/products/${productId}`);

      // Extract the product's relations that have alternate namings to the API
      const { stores, addons } = data;

      // Delete the product's API named relations from the response data
      delete data.stores;
      delete data.addons;

      // If the terminals product does not have an index
      if (this.product?.index === undefined) {
        // Initialise the basket product
        this.initialiseBasketProduct(data);
      } else {
        // Otherwise, update the terminals product's product data
        this.product = {
          ...data,
          index: this.product.index,
          basketData: this.product.basketData,
        } as BasketProduct;
      }

      // Set extracted product relations to the product
      (this.product as BasketProduct).sales_channels = stores;
      (this.product as BasketProduct).enhancements = addons;
      (this.product as BasketProduct).categories = (
        this.product as BasketProduct
      ).categories.map((category: Category) => ({
        ...category,
        // @ts-ignore since all returned API data has sales channel set as store
        salesChannel: category.store,
      }));
    },

    /**
     * Search products.
     */
    async searchProducts(salesChannelId: string): Promise<void> {
      const query = { ...useRoute().query };

      if (query.search === undefined) {
        query.search = '';
      }

      // Limit products to products attached to the sales channel
      query['filter[stores.id]'] = salesChannelId;

      // And products with active status
      query['filter[is_active]'] = 'true';

      const { data: products } = await apiGet('/terminal/search/products', {
        query,
      });

      this.products = products;
    },

    /**
     * Search redeem locations.
     */
    async searchRedeemLocations(query: string): Promise<void> {
      const { data: redeemLocations } = await apiGet(
        'terminal/search/redeem-locations',
        { query: { search: query } },
      );

      this.redeemLocations = redeemLocations;
    },
  },
});
