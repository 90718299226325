// Terminal routes
export default [
  '/terminal/sell/:type()/:salesChannelId()',
  '/terminal/sell/:type()/:salesChannelId()/p/:productId()',
  '/terminal/sell/:type()/:salesChannelId()/p/:productId()/enhancements',
  '/terminal/sell/:type()/:salesChannelId()/p/:productId()/personalisation',
  '/terminal/sell/:type()/:salesChannelId()/p/:productId()/monetary',
  '/terminal/sell/:type()/:salesChannelId()/p/:productId()/variant',
  '/terminal/sell/:type()/:salesChannelId()/delivery',
  '/terminal/sell/:type()/:salesChannelId()/summary',
  '/terminal/sell/:type()/:salesChannelId()/complete',
] as string[];
