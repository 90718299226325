import externalRoutes from '~/utils/externalRoutes';

/**
 * Check authentication status.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const auth = useAuthStore();

  // Fetch auth status from the Pinia store
  let { loggedIn } = auth;

  // If the Pinia store does not think we are logged in, double check with the API
  if (!loggedIn) {
    loggedIn = await auth.check();
  }

  let isExternal: boolean;

  // If the current route matches a defined route
  if (to.matched[0]) {
    // Determine if the current route is included in the defined external routes
    isExternal = externalRoutes.includes(to.matched[0].path);
  } else {
    // Otherwise, the route is not external
    isExternal = false;
  }

  // If we are still not logged in and the current route is not an external route
  if (!loggedIn && !isExternal) {
    // Redirect to the login page with the current route as redirect
    return navigateTo({
      name: 'auth-login',
      replace: true,
      query: { redirect: to.fullPath },
    });
  }
});
