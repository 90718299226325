<script setup lang="ts">
import { isExternalRoute } from '~/utils/routes';
import terminalRoutes from '~/utils/terminalRoutes';
import terminalRedeemRoutes from '~/utils/terminalRedeemRoutes';
import type { LayoutKey } from '#build/types/layouts';

/* Computed variables */

/**
 * Is the current route external.
 */
const isExternal: ComputedRef<boolean> = computed((): boolean =>
  isExternalRoute(),
);

/**
 * Is the current route a terminal redeem route.
 */
const isTerminalRedeem: ComputedRef<boolean> = computed((): boolean => {
  // If the current route matches a defined route
  if (useRoute().matched[0]) {
    // Determine if the current route is a terminal redeem route
    return terminalRedeemRoutes.includes(useRoute().matched[0].path);
  }

  return false;
});

/**
 * Is the current route a terminal sell route.
 */
const isTerminalSell: ComputedRef<boolean> = computed((): boolean => {
  // If the current route matches a defined route
  if (useRoute().matched[0]) {
    // Determine if the current route is a terminal sell route
    return terminalSellRoutes.includes(useRoute().matched[0].path);
  }

  return false;
});

/**
 * Is the current route a terminal route.
 */
const isTerminal: ComputedRef<boolean> = computed((): boolean => {
  // If the current route matches a defined route
  if (useRoute().matched[0]) {
    // Determine if the current route is a terminal route
    return terminalRoutes.includes(useRoute().matched[0].path);
  }

  return false;
});

/* Component methods */

/**
 * Retrieve the layout name based on the URL.
 */
const layoutName = (): string => {
  if (isExternal.value) {
    return 'external';
  }

  if (isTerminal.value) {
    return 'terminal-sell';
  }

  if (isTerminalRedeem.value) {
    return 'terminal-redeem';
  }

  if (isTerminalSell.value) {
    return 'terminal-sell';
  }

  return 'sidebar';
};

/* Component events */

onMounted(async (): Promise<void> => {
  // Retrieve the partner's details
  await useCommonStore().retrievePartner();

  // Set the page meta title and favicon
  useHead({
    titleTemplate: `%s - ${useCommonStore().partner.name}`,
    link: [
      {
        rel: 'icon',
        href: useCommonStore().partner.favicon_url,
      },
    ],
  });
});
</script>

<template>
  <NuxtLayout :name="layoutName() as LayoutKey">
    <NuxtPage />
  </NuxtLayout>

  <UNotifications>
    <template #title="{ title }">
      <span v-html="title" class="notification-title" />
    </template>

    <template #description="{ description }">
      <span v-html="description" class="notification-description" />
    </template>
  </UNotifications>
</template>
